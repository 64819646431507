/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MobileType,
    MobileTypeFromJSON,
    MobileTypeFromJSONTyped,
    MobileTypeToJSON,
} from './MobileType';

/**
 * 
 * @export
 * @interface AddDeviceTokenRequest
 */
export interface AddDeviceTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDeviceTokenRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDeviceTokenRequest
     */
    deviceToken?: string | null;
    /**
     * 
     * @type {MobileType}
     * @memberof AddDeviceTokenRequest
     */
    mobileType?: MobileType;
}

export function AddDeviceTokenRequestFromJSON(json: any): AddDeviceTokenRequest {
    return AddDeviceTokenRequestFromJSONTyped(json, false);
}

export function AddDeviceTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDeviceTokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'deviceToken': !exists(json, 'deviceToken') ? undefined : json['deviceToken'],
        'mobileType': !exists(json, 'mobileType') ? undefined : MobileTypeFromJSON(json['mobileType']),
    };
}

export function AddDeviceTokenRequestToJSON(value?: AddDeviceTokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'deviceToken': value.deviceToken,
        'mobileType': MobileTypeToJSON(value.mobileType),
    };
}

