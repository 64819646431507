/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InitiateResetPasswordRequest
 */
export interface InitiateResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof InitiateResetPasswordRequest
     */
    email?: string | null;
}

export function InitiateResetPasswordRequestFromJSON(json: any): InitiateResetPasswordRequest {
    return InitiateResetPasswordRequestFromJSONTyped(json, false);
}

export function InitiateResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitiateResetPasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function InitiateResetPasswordRequestToJSON(value?: InitiateResetPasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

