/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InitiateResetPasswordRequest,
    InitiateResetPasswordRequestFromJSON,
    InitiateResetPasswordRequestToJSON,
    ResetPasswordRequest,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
} from '../models';

export interface ResetPasswordInitiateResetPasswordPostRequest {
    initiateResetPasswordRequest: InitiateResetPasswordRequest;
}

export interface ResetPasswordResetPasswordPostRequest {
    resetPasswordRequest: ResetPasswordRequest;
}

/**
 * 
 */
export class ResetPasswordApi extends runtime.BaseAPI {

    /**
     */
    async resetPasswordInitiateResetPasswordPostRaw(requestParameters: ResetPasswordInitiateResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.initiateResetPasswordRequest === null || requestParameters.initiateResetPasswordRequest === undefined) {
            throw new runtime.RequiredError('initiateResetPasswordRequest','Required parameter requestParameters.initiateResetPasswordRequest was null or undefined when calling resetPasswordInitiateResetPasswordPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ResetPassword/InitiateResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitiateResetPasswordRequestToJSON(requestParameters.initiateResetPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPasswordInitiateResetPasswordPost(requestParameters: ResetPasswordInitiateResetPasswordPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.resetPasswordInitiateResetPasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resetPasswordResetPasswordPostRaw(requestParameters: ResetPasswordResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordRequest === null || requestParameters.resetPasswordRequest === undefined) {
            throw new runtime.RequiredError('resetPasswordRequest','Required parameter requestParameters.resetPasswordRequest was null or undefined when calling resetPasswordResetPasswordPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ResetPassword/ResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPasswordResetPasswordPost(requestParameters: ResetPasswordResetPasswordPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.resetPasswordResetPasswordPostRaw(requestParameters, initOverrides);
    }

}
