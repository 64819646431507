/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptInvitationRequest,
    AcceptInvitationRequestFromJSON,
    AcceptInvitationRequestToJSON,
    GetInvitationResponse,
    GetInvitationResponseFromJSON,
    GetInvitationResponseToJSON,
} from '../models';

export interface InvitationAcceptPostRequest {
    acceptInvitationRequest: AcceptInvitationRequest;
}

export interface InvitationGuidGetRequest {
    guid: string;
}

/**
 * 
 */
export class InvitationApi extends runtime.BaseAPI {

    /**
     */
    async invitationAcceptPostRaw(requestParameters: InvitationAcceptPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.acceptInvitationRequest === null || requestParameters.acceptInvitationRequest === undefined) {
            throw new runtime.RequiredError('acceptInvitationRequest','Required parameter requestParameters.acceptInvitationRequest was null or undefined when calling invitationAcceptPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Invitation/Accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptInvitationRequestToJSON(requestParameters.acceptInvitationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async invitationAcceptPost(requestParameters: InvitationAcceptPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.invitationAcceptPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async invitationGuidGetRaw(requestParameters: InvitationGuidGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetInvitationResponse>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling invitationGuidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Invitation/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(requestParameters.guid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvitationResponseFromJSON(jsonValue));
    }

    /**
     */
    async invitationGuidGet(requestParameters: InvitationGuidGetRequest, initOverrides?: RequestInit): Promise<GetInvitationResponse> {
        const response = await this.invitationGuidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
