/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicTrack,
    TopicTrackFromJSON,
    TopicTrackFromJSONTyped,
    TopicTrackToJSON,
} from './TopicTrack';

/**
 * 
 * @export
 * @interface GetTopicTrackResponse
 */
export interface GetTopicTrackResponse {
    /**
     * 
     * @type {TopicTrack}
     * @memberof GetTopicTrackResponse
     */
    track?: TopicTrack;
}

export function GetTopicTrackResponseFromJSON(json: any): GetTopicTrackResponse {
    return GetTopicTrackResponseFromJSONTyped(json, false);
}

export function GetTopicTrackResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopicTrackResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'track': !exists(json, 'track') ? undefined : TopicTrackFromJSON(json['track']),
    };
}

export function GetTopicTrackResponseToJSON(value?: GetTopicTrackResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'track': TopicTrackToJSON(value.track),
    };
}

