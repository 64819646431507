/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Track,
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
} from './Track';

/**
 * 
 * @export
 * @interface GetAllTracksResponse
 */
export interface GetAllTracksResponse {
    /**
     * 
     * @type {Array<Track>}
     * @memberof GetAllTracksResponse
     */
    tracks?: Array<Track> | null;
}

export function GetAllTracksResponseFromJSON(json: any): GetAllTracksResponse {
    return GetAllTracksResponseFromJSONTyped(json, false);
}

export function GetAllTracksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllTracksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tracks': !exists(json, 'tracks') ? undefined : (json['tracks'] === null ? null : (json['tracks'] as Array<any>).map(TrackFromJSON)),
    };
}

export function GetAllTracksResponseToJSON(value?: GetAllTracksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tracks': value.tracks === undefined ? undefined : (value.tracks === null ? null : (value.tracks as Array<any>).map(TrackToJSON)),
    };
}

