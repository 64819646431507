/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddDeviceTokenRequest,
    AddDeviceTokenRequestFromJSON,
    AddDeviceTokenRequestToJSON,
    SendInBlueRequest,
    SendInBlueRequestFromJSON,
    SendInBlueRequestToJSON,
} from '../models';

export interface NotificationAddDeviceTokenPostRequest {
    addDeviceTokenRequest: AddDeviceTokenRequest;
}

export interface NotificationMessagePostRequest {
    message: string;
    sendInBlueRequest: SendInBlueRequest;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     */
    async notificationAddDeviceTokenPostRaw(requestParameters: NotificationAddDeviceTokenPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addDeviceTokenRequest === null || requestParameters.addDeviceTokenRequest === undefined) {
            throw new runtime.RequiredError('addDeviceTokenRequest','Required parameter requestParameters.addDeviceTokenRequest was null or undefined when calling notificationAddDeviceTokenPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Notification/AddDeviceToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDeviceTokenRequestToJSON(requestParameters.addDeviceTokenRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationAddDeviceTokenPost(requestParameters: NotificationAddDeviceTokenPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationAddDeviceTokenPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationMessagePostRaw(requestParameters: NotificationMessagePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.message === null || requestParameters.message === undefined) {
            throw new runtime.RequiredError('message','Required parameter requestParameters.message was null or undefined when calling notificationMessagePost.');
        }

        if (requestParameters.sendInBlueRequest === null || requestParameters.sendInBlueRequest === undefined) {
            throw new runtime.RequiredError('sendInBlueRequest','Required parameter requestParameters.sendInBlueRequest was null or undefined when calling notificationMessagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Notification/{message}`.replace(`{${"message"}}`, encodeURIComponent(String(requestParameters.message))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendInBlueRequestToJSON(requestParameters.sendInBlueRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationMessagePost(requestParameters: NotificationMessagePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationMessagePostRaw(requestParameters, initOverrides);
    }

}
