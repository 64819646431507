/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountInfoRequest,
    AccountInfoRequestFromJSON,
    AccountInfoRequestToJSON,
    AccountInfoResponse,
    AccountInfoResponseFromJSON,
    AccountInfoResponseToJSON,
    AccountSettingsRequest,
    AccountSettingsRequestFromJSON,
    AccountSettingsRequestToJSON,
    AccountSettingsResponse,
    AccountSettingsResponseFromJSON,
    AccountSettingsResponseToJSON,
    ChangePasswordRequest,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
} from '../models';

export interface MyPageAccountInfoPutRequest {
    accountInfoRequest: AccountInfoRequest;
}

export interface MyPageAccountSettingsPutRequest {
    accountSettingsRequest: AccountSettingsRequest;
}

export interface MyPageChangePasswordPostRequest {
    changePasswordRequest: ChangePasswordRequest;
}

/**
 * 
 */
export class MyPageApi extends runtime.BaseAPI {

    /**
     */
    async myPageAccountInfoGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/MyPage/AccountInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async myPageAccountInfoGet(initOverrides?: RequestInit): Promise<AccountInfoResponse> {
        const response = await this.myPageAccountInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async myPageAccountInfoPutRaw(requestParameters: MyPageAccountInfoPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountInfoRequest === null || requestParameters.accountInfoRequest === undefined) {
            throw new runtime.RequiredError('accountInfoRequest','Required parameter requestParameters.accountInfoRequest was null or undefined when calling myPageAccountInfoPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/MyPage/AccountInfo`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountInfoRequestToJSON(requestParameters.accountInfoRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async myPageAccountInfoPut(requestParameters: MyPageAccountInfoPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.myPageAccountInfoPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async myPageAccountSettingsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/MyPage/AccountSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountSettingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async myPageAccountSettingsGet(initOverrides?: RequestInit): Promise<AccountSettingsResponse> {
        const response = await this.myPageAccountSettingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async myPageAccountSettingsPutRaw(requestParameters: MyPageAccountSettingsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountSettingsResponse>> {
        if (requestParameters.accountSettingsRequest === null || requestParameters.accountSettingsRequest === undefined) {
            throw new runtime.RequiredError('accountSettingsRequest','Required parameter requestParameters.accountSettingsRequest was null or undefined when calling myPageAccountSettingsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/MyPage/AccountSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountSettingsRequestToJSON(requestParameters.accountSettingsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountSettingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async myPageAccountSettingsPut(requestParameters: MyPageAccountSettingsPutRequest, initOverrides?: RequestInit): Promise<AccountSettingsResponse> {
        const response = await this.myPageAccountSettingsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async myPageChangePasswordPostRaw(requestParameters: MyPageChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changePasswordRequest === null || requestParameters.changePasswordRequest === undefined) {
            throw new runtime.RequiredError('changePasswordRequest','Required parameter requestParameters.changePasswordRequest was null or undefined when calling myPageChangePasswordPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/MyPage/ChangePassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters.changePasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async myPageChangePasswordPost(requestParameters: MyPageChangePasswordPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.myPageChangePasswordPostRaw(requestParameters, initOverrides);
    }

}
