/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TopicStatus {
    Previous = 'Previous',
    Ongoing = 'Ongoing',
    Next = 'Next',
    Future = 'Future'
}

export function TopicStatusFromJSON(json: any): TopicStatus {
    return TopicStatusFromJSONTyped(json, false);
}

export function TopicStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicStatus {
    return json as TopicStatus;
}

export function TopicStatusToJSON(value?: TopicStatus | null): any {
    return value as any;
}

