import { MobileType } from "api";

const userAgent = navigator.userAgent;

export function getMobileType() {
  if (userAgent === 'ambitionAndroid') {
    return MobileType.Android;
  } else if (userAgent === 'ambitionIOS') {
    return MobileType.Apple;
  }

  return null;
}

export const mobileType = getMobileType();

export const isApp = mobileType !== null;

export function getPushToken() {
  if (isApp) {
    const obj = {
      function: 'getPushToken',
      callbackFunction: 'didReceivePushToken',
    };

    if (mobileType === MobileType.Android) {
      window.appmostCallback.postMessage(JSON.stringify(obj));
    } else if (mobileType === MobileType.Apple) {
      window.webkit.messageHandlers.appmostCallback.postMessage(obj);
    }
  }
}
