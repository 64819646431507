/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MobileType {
    Android = 'Android',
    Apple = 'Apple'
}

export function MobileTypeFromJSON(json: any): MobileType {
    return MobileTypeFromJSONTyped(json, false);
}

export function MobileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileType {
    return json as MobileType;
}

export function MobileTypeToJSON(value?: MobileType | null): any {
    return value as any;
}

