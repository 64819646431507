/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicTrack,
    TopicTrackFromJSON,
    TopicTrackFromJSONTyped,
    TopicTrackToJSON,
} from './TopicTrack';

/**
 * 
 * @export
 * @interface GetTopicsResponse
 */
export interface GetTopicsResponse {
    /**
     * 
     * @type {Array<TopicTrack>}
     * @memberof GetTopicsResponse
     */
    tracks?: Array<TopicTrack> | null;
}

export function GetTopicsResponseFromJSON(json: any): GetTopicsResponse {
    return GetTopicsResponseFromJSONTyped(json, false);
}

export function GetTopicsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopicsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tracks': !exists(json, 'tracks') ? undefined : (json['tracks'] === null ? null : (json['tracks'] as Array<any>).map(TopicTrackFromJSON)),
    };
}

export function GetTopicsResponseToJSON(value?: GetTopicsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tracks': value.tracks === undefined ? undefined : (value.tracks === null ? null : (value.tracks as Array<any>).map(TopicTrackToJSON)),
    };
}

