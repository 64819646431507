import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation, NavLink, Outlet } from 'react-router-dom';

import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  GlobalStyles,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';

import { ReactComponent as Empower } from 'images/icons/menu-empower.svg';
import { ReactComponent as Bookmarks } from 'images/icons/menu-bookmarks.svg';
import { ReactComponent as MyAccount } from 'images/icons/menu-my-account.svg';
import { ReactComponent as Tracks } from 'images/icons/menu-tracks.svg';

import { useAuth } from 'authContext';

import Loader from 'components/Loader';

function Authorized() {
  const [value, setValue] = useState(0);
  const [title, setTitle] = useState('My Ambition');

  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();

  const menu = [
    { to: '/', label: 'My Ambition', icon: Empower },
    { to: '/tracks', label: 'Tracks', icon: Tracks },
    { to: '/bookmarks', label: 'Bookmarks', icon: Bookmarks },
    { to: '/account', label: 'Account', icon: MyAccount, iconButton: true },
  ];

  useEffect(() => {
    const selected = menu.find(item => location.pathname === item.to);

    if (selected) {
      setTitle(selected.label);
      setValue(menu.findIndex(object => object.to === selected.to));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && user.firstTimeLogin) {
      navigate('/wizard/one');
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            backgroundImage: 'none',
          },
          a: {
            color: '#D84F71',
          },
        }}
      />
      <Box sx={{ pb: { xs: 9, sm: 0 } }}>
        <AppBar
          position="sticky"
          sx={{
            p: 2,
            textAlign: 'center',
            paddingTop: 'calc(16px + env(safe-area-inset-top))',
          }}
        >
          <Typography variant="h3" component="h1" sx={{ display: { xs: 'block', sm: 'none' } }}>
            {title}
          </Typography>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
                justifyContent: 'center',
                position: 'relative',
              },
            }}
          >
            <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
              {menu
                .filter(item => !item.iconButton)
                .map((item, index) => (
                  <Button
                    key={index}
                    component={NavLink}
                    to={item.to}
                    sx={{
                      color: 'common.white',
                      fontSize: '1rem',
                      '&.active': {
                        textDecoration: 'underline',
                      },
                      whiteSpace: 'nowrap',
                    }}
                    startIcon={<SvgIcon component={item.icon} inheritViewBox />}
                  >
                    {item.label}
                  </Button>
                ))}
            </Stack>
            <Stack
              direction="row"
              spacing={{ xs: 1, md: 2 }}
              sx={{
                position: 'absolute',
                right: 2,
              }}
            >
              {menu
                .filter(item => item.iconButton)
                .map((item, index) => (
                  <IconButton
                    key={index}
                    component={NavLink}
                    to="/account"
                    aria-label="My Account"
                    sx={{
                      color: 'common.white',
                    }}
                  >
                    <SvgIcon component={MyAccount} inheritViewBox />
                  </IconButton>
                ))}
            </Stack>
          </Box>
        </AppBar>
        <Outlet />
        <Loader position />
        <Paper
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: 'fixed',
            zIndex: 2,
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(20px)',
            paddingLeft: 'env(safe-area-inset-left)',
            paddingRight: 'env(safe-area-inset-right)',
            paddingBottom: 'calc(16px + env(safe-area-inset-bottom))',
            backgroundColor: '#F9F9F9'
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{ backgroundColor: '#F9F9F9' }}
          >
            {menu.map((item, index) => (
              <BottomNavigationAction
                key={index}
                component={NavLink}
                to={item.to}
                label={item.label}
                icon={<SvgIcon component={item.icon} inheritViewBox />}
              />
            ))}
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
}

export default Authorized;
