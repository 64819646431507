/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityType,
    ActivityTypeFromJSON,
    ActivityTypeFromJSONTyped,
    ActivityTypeToJSON,
} from './ActivityType';

/**
 * 
 * @export
 * @interface LogActivityRequest
 */
export interface LogActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof LogActivityRequest
     */
    taskId?: number;
    /**
     * 
     * @type {number}
     * @memberof LogActivityRequest
     */
    topicId?: number;
    /**
     * 
     * @type {number}
     * @memberof LogActivityRequest
     */
    trackId?: number;
    /**
     * 
     * @type {ActivityType}
     * @memberof LogActivityRequest
     */
    type?: ActivityType;
}

export function LogActivityRequestFromJSON(json: any): LogActivityRequest {
    return LogActivityRequestFromJSONTyped(json, false);
}

export function LogActivityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogActivityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'topicId': !exists(json, 'topicId') ? undefined : json['topicId'],
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'type': !exists(json, 'type') ? undefined : ActivityTypeFromJSON(json['type']),
    };
}

export function LogActivityRequestToJSON(value?: LogActivityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskId': value.taskId,
        'topicId': value.topicId,
        'trackId': value.trackId,
        'type': ActivityTypeToJSON(value.type),
    };
}

