/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountSettingsRequest
 */
export interface AccountSettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsRequest
     */
    mobileNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsRequest
     */
    emailNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsRequest
     */
    weeklyTodoEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsRequest
     */
    inactivityReminder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsRequest
     */
    progressReminder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsRequest
     */
    liveSessionReminder?: boolean;
}

export function AccountSettingsRequestFromJSON(json: any): AccountSettingsRequest {
    return AccountSettingsRequestFromJSONTyped(json, false);
}

export function AccountSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobileNotifications': !exists(json, 'mobileNotifications') ? undefined : json['mobileNotifications'],
        'emailNotifications': !exists(json, 'emailNotifications') ? undefined : json['emailNotifications'],
        'weeklyTodoEmail': !exists(json, 'weeklyTodoEmail') ? undefined : json['weeklyTodoEmail'],
        'inactivityReminder': !exists(json, 'inactivityReminder') ? undefined : json['inactivityReminder'],
        'progressReminder': !exists(json, 'progressReminder') ? undefined : json['progressReminder'],
        'liveSessionReminder': !exists(json, 'liveSessionReminder') ? undefined : json['liveSessionReminder'],
    };
}

export function AccountSettingsRequestToJSON(value?: AccountSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobileNotifications': value.mobileNotifications,
        'emailNotifications': value.emailNotifications,
        'weeklyTodoEmail': value.weeklyTodoEmail,
        'inactivityReminder': value.inactivityReminder,
        'progressReminder': value.progressReminder,
        'liveSessionReminder': value.liveSessionReminder,
    };
}

