/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendInBlueRequest
 */
export interface SendInBlueRequest {
    /**
     * 
     * @type {number}
     * @memberof SendInBlueRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SendInBlueRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendInBlueRequest
     */
    event?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendInBlueRequest
     */
    key?: string | null;
}

export function SendInBlueRequestFromJSON(json: any): SendInBlueRequest {
    return SendInBlueRequestFromJSONTyped(json, false);
}

export function SendInBlueRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendInBlueRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function SendInBlueRequestToJSON(value?: SendInBlueRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'event': value.event,
        'key': value.key,
    };
}

