/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddBookmarkRequest,
    AddBookmarkRequestFromJSON,
    AddBookmarkRequestToJSON,
    GetAllBookmarksResponse,
    GetAllBookmarksResponseFromJSON,
    GetAllBookmarksResponseToJSON,
    RemoveBookmarkRequest,
    RemoveBookmarkRequestFromJSON,
    RemoveBookmarkRequestToJSON,
} from '../models';

export interface BookmarkPostRequest {
    addBookmarkRequest: AddBookmarkRequest;
}

export interface BookmarkRemovePostRequest {
    removeBookmarkRequest: RemoveBookmarkRequest;
}

/**
 * 
 */
export class BookmarksApi extends runtime.BaseAPI {

    /**
     */
    async bookmarkPostRaw(requestParameters: BookmarkPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addBookmarkRequest === null || requestParameters.addBookmarkRequest === undefined) {
            throw new runtime.RequiredError('addBookmarkRequest','Required parameter requestParameters.addBookmarkRequest was null or undefined when calling bookmarkPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Bookmark`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBookmarkRequestToJSON(requestParameters.addBookmarkRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bookmarkPost(requestParameters: BookmarkPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.bookmarkPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async bookmarkRemovePostRaw(requestParameters: BookmarkRemovePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.removeBookmarkRequest === null || requestParameters.removeBookmarkRequest === undefined) {
            throw new runtime.RequiredError('removeBookmarkRequest','Required parameter requestParameters.removeBookmarkRequest was null or undefined when calling bookmarkRemovePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Bookmark/Remove`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveBookmarkRequestToJSON(requestParameters.removeBookmarkRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bookmarkRemovePost(requestParameters: BookmarkRemovePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.bookmarkRemovePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async bookmarksGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<GetAllBookmarksResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Bookmarks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAllBookmarksResponseFromJSON));
    }

    /**
     */
    async bookmarksGet(initOverrides?: RequestInit): Promise<Array<GetAllBookmarksResponse>> {
        const response = await this.bookmarksGetRaw(initOverrides);
        return await response.value();
    }

}
