/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LogActivityRequest,
    LogActivityRequestFromJSON,
    LogActivityRequestToJSON,
} from '../models';

export interface LogActivityPostRequest {
    logActivityRequest: LogActivityRequest;
}

/**
 * 
 */
export class LogingApi extends runtime.BaseAPI {

    /**
     */
    async logActivityPostRaw(requestParameters: LogActivityPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LogActivityRequest>> {
        if (requestParameters.logActivityRequest === null || requestParameters.logActivityRequest === undefined) {
            throw new runtime.RequiredError('logActivityRequest','Required parameter requestParameters.logActivityRequest was null or undefined when calling logActivityPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Log/Activity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogActivityRequestToJSON(requestParameters.logActivityRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogActivityRequestFromJSON(jsonValue));
    }

    /**
     */
    async logActivityPost(requestParameters: LogActivityPostRequest, initOverrides?: RequestInit): Promise<LogActivityRequest> {
        const response = await this.logActivityPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
