/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMeetingLinkResponse
 */
export interface GetMeetingLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof GetMeetingLinkResponse
     */
    meetingLink?: string | null;
}

export function GetMeetingLinkResponseFromJSON(json: any): GetMeetingLinkResponse {
    return GetMeetingLinkResponseFromJSONTyped(json, false);
}

export function GetMeetingLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMeetingLinkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meetingLink': !exists(json, 'meetingLink') ? undefined : json['meetingLink'],
    };
}

export function GetMeetingLinkResponseToJSON(value?: GetMeetingLinkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meetingLink': value.meetingLink,
    };
}

