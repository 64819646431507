import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { CssBaseline } from '@mui/material';

import { LoadingProvider } from 'loadingContext';
import { AuthProvider } from 'authContext';
import { UserInfoProvider } from 'userInfoContext';

import App from 'App';

import { ConfigLoader } from 'components/Config';
import { ToastProvider } from 'toastContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#582E4F',
    },
    secondary: {
      main: '#14816D',
      light: '#66BD94',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#D84F71',
    },
    text: {
      primary: '#282828',
      secondary: '#F6F2E4',
    },
    grey: {
      300: '#D6D6D6',
      400: '#C6C6C6',
      500: '#979797',
      600: '#737373',
      700: '#555555',
      900: '#282828',
    },
    green: {
      main: '#14816D',
    },
    background: {
      default: '#E5E5E5',
    },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
    body1: {
      fontSize: '1rem',
    },
    h1: {
      fontSize: '2rem', // 32px
      fontWeight: 600,
      lineHeight: 1.5, // 48px
      letterSpacing: 0,
      '@media (max-width:600px)': {
        fontSize: '1.5rem', // Adjusted size for XS screens
      },
    },
    h2: {
      fontSize: '1.25rem', // 20px
      fontWeight: 700,
      lineHeight: 1.4, // 28px
      letterSpacing: 0,
    },
    h3: {
      fontSize: '1.125rem', // 18px
      fontWeight: 600,
      lineHeight: 1.5, // 27px
    },
    h4: {
      fontSize: '1rem', // 16px
      fontWeight: 500,
      lineHeight: 1.5, // 24px
    },
    button: {
      textTransform: 'none',
      fontSize: '1.125rem',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#000',
          whiteSpace: 'nowrap',
          '&.Mui-selected': {
            color: '#D84F71',
            '& .MuiBottomNavigationAction-label': {
              fontSize: '0.625rem',
            },
          },
        },
        label: {
          fontSize: '0.625rem',
        },
        selected: {},
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::placeholder': {
            fontSize: '1.125rem',
          },
          '& .MuiFilledInput-root': {
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#E2E2E2',
            },
            '&.Mui-focused': {
              backgroundColor: '#FFFFFF',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#E2E2E2',
          },
          '&.Mui-focused': {
            backgroundColor: '#FFFFFF',
            '.MuiSelect-filled': {
              backgroundColor: '#FFFFFF',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.60)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.60)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#979797',
        },
      },
      defaultProps: {
        color: 'success',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        outlined: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
          '&:active': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#282828',
          '&.Mui-selected': {
            color: '#282828',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: '#D84F71',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          '& .MuiChip-avatar': {
            width: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0)',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigLoader>
    <BrowserRouter>
      <HelmetProvider>
        <LoadingProvider>
          <ToastProvider>
            <AuthProvider>
              <UserInfoProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <App />
                </ThemeProvider>
              </UserInfoProvider>
            </AuthProvider>
          </ToastProvider>
        </LoadingProvider>
      </HelmetProvider>
    </BrowserRouter>
  </ConfigLoader>
);
