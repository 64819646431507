/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaskResponse,
    TaskResponseFromJSON,
    TaskResponseFromJSONTyped,
    TaskResponseToJSON,
} from './TaskResponse';
import {
    WeekType,
    WeekTypeFromJSON,
    WeekTypeFromJSONTyped,
    WeekTypeToJSON,
} from './WeekType';

/**
 * 
 * @export
 * @interface WeekResponse
 */
export interface WeekResponse {
    /**
     * 
     * @type {number}
     * @memberof WeekResponse
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof WeekResponse
     */
    endDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WeekResponse
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Array<TaskResponse>}
     * @memberof WeekResponse
     */
    tasks?: Array<TaskResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof WeekResponse
     */
    weekNumber?: number | null;
    /**
     * 
     * @type {WeekType}
     * @memberof WeekResponse
     */
    type?: WeekType;
    /**
     * 
     * @type {number}
     * @memberof WeekResponse
     */
    year?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WeekResponse
     */
    weeklyInfoTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WeekResponse
     */
    weeklyInfoDescription?: string | null;
}

export function WeekResponseFromJSON(json: any): WeekResponse {
    return WeekResponseFromJSONTyped(json, false);
}

export function WeekResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(TaskResponseFromJSON)),
        'weekNumber': !exists(json, 'weekNumber') ? undefined : json['weekNumber'],
        'type': !exists(json, 'type') ? undefined : WeekTypeFromJSON(json['type']),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'weeklyInfoTitle': !exists(json, 'weeklyInfoTitle') ? undefined : json['weeklyInfoTitle'],
        'weeklyInfoDescription': !exists(json, 'weeklyInfoDescription') ? undefined : json['weeklyInfoDescription'],
    };
}

export function WeekResponseToJSON(value?: WeekResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(TaskResponseToJSON)),
        'weekNumber': value.weekNumber,
        'type': WeekTypeToJSON(value.type),
        'year': value.year,
        'weeklyInfoTitle': value.weeklyInfoTitle,
        'weeklyInfoDescription': value.weeklyInfoDescription,
    };
}

