/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetMeetingLinkResponse,
    GetMeetingLinkResponseFromJSON,
    GetMeetingLinkResponseToJSON,
    SetDoneRequest,
    SetDoneRequestFromJSON,
    SetDoneRequestToJSON,
    SetDoneResponse,
    SetDoneResponseFromJSON,
    SetDoneResponseToJSON,
    SetNotDoneRequest,
    SetNotDoneRequestFromJSON,
    SetNotDoneRequestToJSON,
} from '../models';

export interface TaskDonePostRequest {
    setDoneRequest: SetDoneRequest;
}

export interface TaskGetMeetingLinkPasswordGetRequest {
    password: string;
}

export interface TaskNotDonePostRequest {
    setNotDoneRequest: SetNotDoneRequest;
}

/**
 * 
 */
export class TasksApi extends runtime.BaseAPI {

    /**
     */
    async taskDonePostRaw(requestParameters: TaskDonePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SetDoneResponse>> {
        if (requestParameters.setDoneRequest === null || requestParameters.setDoneRequest === undefined) {
            throw new runtime.RequiredError('setDoneRequest','Required parameter requestParameters.setDoneRequest was null or undefined when calling taskDonePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Task/Done`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDoneRequestToJSON(requestParameters.setDoneRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDoneResponseFromJSON(jsonValue));
    }

    /**
     */
    async taskDonePost(requestParameters: TaskDonePostRequest, initOverrides?: RequestInit): Promise<SetDoneResponse> {
        const response = await this.taskDonePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async taskGetMeetingLinkPasswordGetRaw(requestParameters: TaskGetMeetingLinkPasswordGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMeetingLinkResponse>> {
        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling taskGetMeetingLinkPasswordGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Task/GetMeetingLink/{password}`.replace(`{${"password"}}`, encodeURIComponent(String(requestParameters.password))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMeetingLinkResponseFromJSON(jsonValue));
    }

    /**
     */
    async taskGetMeetingLinkPasswordGet(requestParameters: TaskGetMeetingLinkPasswordGetRequest, initOverrides?: RequestInit): Promise<GetMeetingLinkResponse> {
        const response = await this.taskGetMeetingLinkPasswordGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async taskNotDonePostRaw(requestParameters: TaskNotDonePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setNotDoneRequest === null || requestParameters.setNotDoneRequest === undefined) {
            throw new runtime.RequiredError('setNotDoneRequest','Required parameter requestParameters.setNotDoneRequest was null or undefined when calling taskNotDonePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Task/NotDone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetNotDoneRequestToJSON(requestParameters.setNotDoneRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async taskNotDonePost(requestParameters: TaskNotDonePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.taskNotDonePostRaw(requestParameters, initOverrides);
    }

}
