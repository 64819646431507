/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountInfoResponse
 */
export interface AccountInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    organization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AccountInfoResponse
     */
    subscriptionStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AccountInfoResponse
     */
    subscriptionEndDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AccountInfoResponse
     */
    lastLogin?: Date | null;
}

export function AccountInfoResponseFromJSON(json: any): AccountInfoResponse {
    return AccountInfoResponseFromJSONTyped(json, false);
}

export function AccountInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'subscriptionStartDate': !exists(json, 'subscriptionStartDate') ? undefined : (json['subscriptionStartDate'] === null ? null : new Date(json['subscriptionStartDate'])),
        'subscriptionEndDate': !exists(json, 'subscriptionEndDate') ? undefined : (json['subscriptionEndDate'] === null ? null : new Date(json['subscriptionEndDate'])),
        'lastLogin': !exists(json, 'lastLogin') ? undefined : (json['lastLogin'] === null ? null : new Date(json['lastLogin'])),
    };
}

export function AccountInfoResponseToJSON(value?: AccountInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'organization': value.organization,
        'country': value.country,
        'city': value.city,
        'title': value.title,
        'phoneNumber': value.phoneNumber,
        'subscriptionStartDate': value.subscriptionStartDate === undefined ? undefined : (value.subscriptionStartDate === null ? null : value.subscriptionStartDate.toISOString()),
        'subscriptionEndDate': value.subscriptionEndDate === undefined ? undefined : (value.subscriptionEndDate === null ? null : value.subscriptionEndDate.toISOString()),
        'lastLogin': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
    };
}

