import { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const ToastContext = createContext();

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider = ({ children }) => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const showToast = (message, severity = 'success') => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const value = {
    showToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <MuiAlert onClose={handleCloseToast} severity={toastSeverity} sx={{ width: '100%', whiteSpace: 'pre-line' }}>
          {toastMessage}
        </MuiAlert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
