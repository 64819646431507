/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationCountResponse
 */
export interface NotificationCountResponse {
    /**
     * 
     * @type {string}
     * @memberof NotificationCountResponse
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationCountResponse
     */
    count?: number;
}

export function NotificationCountResponseFromJSON(json: any): NotificationCountResponse {
    return NotificationCountResponseFromJSONTyped(json, false);
}

export function NotificationCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function NotificationCountResponseToJSON(value?: NotificationCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'count': value.count,
    };
}

