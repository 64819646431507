import { Outlet } from 'react-router-dom';

import { Container, GlobalStyles } from '@mui/material';

import AmbitionMountainsBw from 'images/backgrounds/ambition-mountains-bw.svg';

function Unauthorized() {
  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: '#582E4F',
            backgroundImage: `url(${AmbitionMountainsBw})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom right',
            minHeight: '100vh',
          },
        }}
      />
      <Container maxWidth="xs" sx={{ py: 6, textAlign: 'center' }}>
        <Outlet />
      </Container>
    </>
  );
}

export default Unauthorized;
