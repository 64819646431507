/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberStatus,
    MemberStatusFromJSON,
    MemberStatusFromJSONTyped,
    MemberStatusToJSON,
} from './MemberStatus';
import {
    UserSettingsDto,
    UserSettingsDtoFromJSON,
    UserSettingsDtoFromJSONTyped,
    UserSettingsDtoToJSON,
} from './UserSettingsDto';

/**
 * 
 * @export
 * @interface EmpowerUserDto
 */
export interface EmpowerUserDto {
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    organization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    fastCommentSSOId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    brevoId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmpowerUserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmpowerUserDto
     */
    firstTimeLogin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmpowerUserDto
     */
    isPaying?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmpowerUserDto
     */
    isSubcontractor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmpowerUserDto
     */
    sendInvitationEmail?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EmpowerUserDto
     */
    inviteAccepted?: Date | null;
    /**
     * 
     * @type {MemberStatus}
     * @memberof EmpowerUserDto
     */
    memberStatus?: MemberStatus;
    /**
     * 
     * @type {Date}
     * @memberof EmpowerUserDto
     */
    subscriptionStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EmpowerUserDto
     */
    subscriptionEndDate?: Date | null;
    /**
     * 
     * @type {UserSettingsDto}
     * @memberof EmpowerUserDto
     */
    settings?: UserSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    readonly displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpowerUserDto
     */
    readonly fastCommentsUsername?: string | null;
}

export function EmpowerUserDtoFromJSON(json: any): EmpowerUserDto {
    return EmpowerUserDtoFromJSONTyped(json, false);
}

export function EmpowerUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmpowerUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'fastCommentSSOId': !exists(json, 'fastCommentSSOId') ? undefined : json['fastCommentSSOId'],
        'brevoId': !exists(json, 'brevoId') ? undefined : json['brevoId'],
        'emailConfirmed': !exists(json, 'emailConfirmed') ? undefined : json['emailConfirmed'],
        'firstTimeLogin': !exists(json, 'firstTimeLogin') ? undefined : json['firstTimeLogin'],
        'isPaying': !exists(json, 'isPaying') ? undefined : json['isPaying'],
        'isSubcontractor': !exists(json, 'isSubcontractor') ? undefined : json['isSubcontractor'],
        'sendInvitationEmail': !exists(json, 'sendInvitationEmail') ? undefined : json['sendInvitationEmail'],
        'inviteAccepted': !exists(json, 'inviteAccepted') ? undefined : (json['inviteAccepted'] === null ? null : new Date(json['inviteAccepted'])),
        'memberStatus': !exists(json, 'memberStatus') ? undefined : MemberStatusFromJSON(json['memberStatus']),
        'subscriptionStartDate': !exists(json, 'subscriptionStartDate') ? undefined : (json['subscriptionStartDate'] === null ? null : new Date(json['subscriptionStartDate'])),
        'subscriptionEndDate': !exists(json, 'subscriptionEndDate') ? undefined : (json['subscriptionEndDate'] === null ? null : new Date(json['subscriptionEndDate'])),
        'settings': !exists(json, 'settings') ? undefined : UserSettingsDtoFromJSON(json['settings']),
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'fastCommentsUsername': !exists(json, 'fastCommentsUsername') ? undefined : json['fastCommentsUsername'],
    };
}

export function EmpowerUserDtoToJSON(value?: EmpowerUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'organization': value.organization,
        'country': value.country,
        'city': value.city,
        'title': value.title,
        'imageUrl': value.imageUrl,
        'phoneNumber': value.phoneNumber,
        'fastCommentSSOId': value.fastCommentSSOId,
        'brevoId': value.brevoId,
        'emailConfirmed': value.emailConfirmed,
        'firstTimeLogin': value.firstTimeLogin,
        'isPaying': value.isPaying,
        'isSubcontractor': value.isSubcontractor,
        'sendInvitationEmail': value.sendInvitationEmail,
        'inviteAccepted': value.inviteAccepted === undefined ? undefined : (value.inviteAccepted === null ? null : value.inviteAccepted.toISOString()),
        'memberStatus': MemberStatusToJSON(value.memberStatus),
        'subscriptionStartDate': value.subscriptionStartDate === undefined ? undefined : (value.subscriptionStartDate === null ? null : value.subscriptionStartDate.toISOString()),
        'subscriptionEndDate': value.subscriptionEndDate === undefined ? undefined : (value.subscriptionEndDate === null ? null : value.subscriptionEndDate.toISOString()),
        'settings': UserSettingsDtoToJSON(value.settings),
    };
}

