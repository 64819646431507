/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountInfoRequest
 */
export interface AccountInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoRequest
     */
    phoneNumber?: string | null;
}

export function AccountInfoRequestFromJSON(json: any): AccountInfoRequest {
    return AccountInfoRequestFromJSONTyped(json, false);
}

export function AccountInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    };
}

export function AccountInfoRequestToJSON(value?: AccountInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'country': value.country,
        'city': value.city,
        'title': value.title,
        'phoneNumber': value.phoneNumber,
    };
}

