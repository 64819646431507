import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

function Wizard() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/wizard') {
      navigate('/wizard/one', { replace: true });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Outlet />
    </>
  );
}

export default Wizard;
