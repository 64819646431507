import { Backdrop, CircularProgress } from '@mui/material';
import { useLoading } from 'loadingContext';

export default function Loader({ open, position }) {
  const { loading } = useLoading();

  open = open ? open : loading;

  return (
    <Backdrop
      sx={{
        color: 'primary.main',
        bgcolor: 'background.default',
        zIndex: theme => theme.zIndex.drawer + 1,
        top: position
          ? {
              xs: 'calc(env(safe-area-inset-top) + 59px)',
              sm: 'calc(env(safe-area-inset-top) + 72px)',
            }
          : 'auto',
        bottom: position
          ? { xs: 'calc(env(safe-area-inset-top) + 73px)', sm: '0' }
          : 'auto',
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
