/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MemberStatus {
    Inactive = 'Inactive',
    Active = 'Active',
    Alumni = 'Alumni',
    Expired = 'Expired',
    Invited = 'Invited'
}

export function MemberStatusFromJSON(json: any): MemberStatus {
    return MemberStatusFromJSONTyped(json, false);
}

export function MemberStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberStatus {
    return json as MemberStatus;
}

export function MemberStatusToJSON(value?: MemberStatus | null): any {
    return value as any;
}

