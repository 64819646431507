/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LiveSessionResponse
 */
export interface LiveSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof LiveSessionResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LiveSessionResponse
     */
    link?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LiveSessionResponse
     */
    liveTime?: Date | null;
}

export function LiveSessionResponseFromJSON(json: any): LiveSessionResponse {
    return LiveSessionResponseFromJSONTyped(json, false);
}

export function LiveSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveSessionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'liveTime': !exists(json, 'liveTime') ? undefined : (json['liveTime'] === null ? null : new Date(json['liveTime'])),
    };
}

export function LiveSessionResponseToJSON(value?: LiveSessionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'link': value.link,
        'liveTime': value.liveTime === undefined ? undefined : (value.liveTime === null ? null : value.liveTime.toISOString()),
    };
}

