/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcceptInvitationRequest
 */
export interface AcceptInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    invitationGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    password?: string | null;
}

export function AcceptInvitationRequestFromJSON(json: any): AcceptInvitationRequest {
    return AcceptInvitationRequestFromJSONTyped(json, false);
}

export function AcceptInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invitationGuid': !exists(json, 'invitationGuid') ? undefined : json['invitationGuid'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function AcceptInvitationRequestToJSON(value?: AcceptInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invitationGuid': value.invitationGuid,
        'password': value.password,
    };
}

