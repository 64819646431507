import { useState } from 'react';
import { useEffect } from 'react';

export const config = {};

function GetConfig() {
  return fetch('/config.json')
    .then(result => result.json())
    .then(data => {
      for (let prop in config) {
        delete config[prop];
      }

      for (let prop_1 in data) {
        config[prop_1] = data[prop_1];
      }

      return config;
    });
}

export function ConfigLoader({ children, loading }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    GetConfig().then(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return loading ? <>{loading}</> : null;
  }

  return <>{children}</>;
}

export default config;
