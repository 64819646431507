/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WeekType {
    Undefined = 'Undefined',
    Intro = 'Intro',
    Explore = 'Explore',
    DeepDive = 'DeepDive',
    Reflect = 'Reflect',
    Break = 'Break'
}

export function WeekTypeFromJSON(json: any): WeekType {
    return WeekTypeFromJSONTyped(json, false);
}

export function WeekTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekType {
    return json as WeekType;
}

export function WeekTypeToJSON(value?: WeekType | null): any {
    return value as any;
}

