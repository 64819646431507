export const parseError = async error => {
  const genericErrMsg = 'Something went wrong. Please try again.';
  try {
    const json = await error.json();
    if (error.status === 400) {
      if (json?.errors) {
        /* Sample JSON response with validation errors
          {
            "type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
            "title": "One or more validation errors occurred.",
            "status": 400,
            "errors": {
                "FirstName": [
                    "'First Name' must not be empty."
                ]
            }
          } 
        */
        let errorMessage = json.title ? `${json.title}\n` : '';
        errorMessage += Object.entries(json.errors)
          .map(([field, messages]) => messages.map(msg => `- ${msg}`).join('\n'))
          .join('\n');

        return errorMessage.trim();
      } else {
        // No specific validation errors found, return generic client error message
        return json?.title ? json.title : json;
      }
    } else {
      return genericErrMsg;
    }
  } catch {
    return genericErrMsg;
  }
};
