/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FastCommentsSSOPayload
 */
export interface FastCommentsSSOPayload {
    /**
     * 
     * @type {string}
     * @memberof FastCommentsSSOPayload
     */
    userDataJSONBase64?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FastCommentsSSOPayload
     */
    verificationHash?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FastCommentsSSOPayload
     */
    timestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof FastCommentsSSOPayload
     */
    loginUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FastCommentsSSOPayload
     */
    logoutUrl?: string | null;
}

export function FastCommentsSSOPayloadFromJSON(json: any): FastCommentsSSOPayload {
    return FastCommentsSSOPayloadFromJSONTyped(json, false);
}

export function FastCommentsSSOPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): FastCommentsSSOPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userDataJSONBase64': !exists(json, 'userDataJSONBase64') ? undefined : json['userDataJSONBase64'],
        'verificationHash': !exists(json, 'verificationHash') ? undefined : json['verificationHash'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'loginUrl': !exists(json, 'loginUrl') ? undefined : json['loginUrl'],
        'logoutUrl': !exists(json, 'logoutUrl') ? undefined : json['logoutUrl'],
    };
}

export function FastCommentsSSOPayloadToJSON(value?: FastCommentsSSOPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userDataJSONBase64': value.userDataJSONBase64,
        'verificationHash': value.verificationHash,
        'timestamp': value.timestamp,
        'loginUrl': value.loginUrl,
        'logoutUrl': value.logoutUrl,
    };
}

