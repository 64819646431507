/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopicTask
 */
export interface TopicTask {
    /**
     * 
     * @type {number}
     * @memberof TopicTask
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicTask
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTask
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTask
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTask
     */
    taskType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopicTask
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicTask
     */
    sortOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTask
     */
    isBookmarked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTask
     */
    isDone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTask
     */
    optional?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTask
     */
    summaryAIGenerated?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTask
     */
    summary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTask
     */
    link?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TopicTask
     */
    liveTime?: Date | null;
}

export function TopicTaskFromJSON(json: any): TopicTask {
    return TopicTaskFromJSONTyped(json, false);
}

export function TopicTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'taskType': !exists(json, 'taskType') ? undefined : json['taskType'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isBookmarked': !exists(json, 'isBookmarked') ? undefined : json['isBookmarked'],
        'isDone': !exists(json, 'isDone') ? undefined : json['isDone'],
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
        'summaryAIGenerated': !exists(json, 'summaryAIGenerated') ? undefined : json['summaryAIGenerated'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'liveTime': !exists(json, 'liveTime') ? undefined : (json['liveTime'] === null ? null : new Date(json['liveTime'])),
    };
}

export function TopicTaskToJSON(value?: TopicTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'title': value.title,
        'description': value.description,
        'taskType': value.taskType,
        'duration': value.duration,
        'sortOrder': value.sortOrder,
        'isBookmarked': value.isBookmarked,
        'isDone': value.isDone,
        'optional': value.optional,
        'summaryAIGenerated': value.summaryAIGenerated,
        'summary': value.summary,
        'link': value.link,
        'liveTime': value.liveTime === undefined ? undefined : (value.liveTime === null ? null : value.liveTime.toISOString()),
    };
}

