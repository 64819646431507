/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetTopicByIdResponse,
    GetTopicByIdResponseFromJSON,
    GetTopicByIdResponseToJSON,
    GetTopicTrackResponse,
    GetTopicTrackResponseFromJSON,
    GetTopicTrackResponseToJSON,
    GetTopicsResponse,
    GetTopicsResponseFromJSON,
    GetTopicsResponseToJSON,
} from '../models';

export interface TopicIdGetRequest {
    id: number;
}

export interface TrackIdTopicsNowGetRequest {
    id: number;
}

export interface TrackIdTopicsPastGetRequest {
    id: number;
}

export interface TrackIdTopicsUpcomingGetRequest {
    id: number;
}

/**
 * 
 */
export class TopicsApi extends runtime.BaseAPI {

    /**
     */
    async topicIdGetRaw(requestParameters: TopicIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicByIdResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling topicIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Topic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicByIdResponseFromJSON(jsonValue));
    }

    /**
     */
    async topicIdGet(requestParameters: TopicIdGetRequest, initOverrides?: RequestInit): Promise<GetTopicByIdResponse> {
        const response = await this.topicIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async topicsNowGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Topics/Now`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicsResponseFromJSON(jsonValue));
    }

    /**
     */
    async topicsNowGet(initOverrides?: RequestInit): Promise<GetTopicsResponse> {
        const response = await this.topicsNowGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async topicsPastGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Topics/Past`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicsResponseFromJSON(jsonValue));
    }

    /**
     */
    async topicsPastGet(initOverrides?: RequestInit): Promise<GetTopicsResponse> {
        const response = await this.topicsPastGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async topicsUpcomingGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Topics/Upcoming`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicsResponseFromJSON(jsonValue));
    }

    /**
     */
    async topicsUpcomingGet(initOverrides?: RequestInit): Promise<GetTopicsResponse> {
        const response = await this.topicsUpcomingGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async trackIdTopicsNowGetRaw(requestParameters: TrackIdTopicsNowGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicTrackResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trackIdTopicsNowGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Track/{id}/Topics/Now`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicTrackResponseFromJSON(jsonValue));
    }

    /**
     */
    async trackIdTopicsNowGet(requestParameters: TrackIdTopicsNowGetRequest, initOverrides?: RequestInit): Promise<GetTopicTrackResponse> {
        const response = await this.trackIdTopicsNowGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async trackIdTopicsPastGetRaw(requestParameters: TrackIdTopicsPastGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicTrackResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trackIdTopicsPastGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Track/{id}/Topics/Past`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicTrackResponseFromJSON(jsonValue));
    }

    /**
     */
    async trackIdTopicsPastGet(requestParameters: TrackIdTopicsPastGetRequest, initOverrides?: RequestInit): Promise<GetTopicTrackResponse> {
        const response = await this.trackIdTopicsPastGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async trackIdTopicsUpcomingGetRaw(requestParameters: TrackIdTopicsUpcomingGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTopicTrackResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trackIdTopicsUpcomingGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Track/{id}/Topics/Upcoming`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicTrackResponseFromJSON(jsonValue));
    }

    /**
     */
    async trackIdTopicsUpcomingGet(requestParameters: TrackIdTopicsUpcomingGetRequest, initOverrides?: RequestInit): Promise<GetTopicTrackResponse> {
        const response = await this.trackIdTopicsUpcomingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
