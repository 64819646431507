/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmpowerUserDto,
    EmpowerUserDtoFromJSON,
    EmpowerUserDtoFromJSONTyped,
    EmpowerUserDtoToJSON,
} from './EmpowerUserDto';

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {EmpowerUserDto}
     * @memberof LoginResponse
     */
    user?: EmpowerUserDto;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    token?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LoginResponse
     */
    validTo?: Date;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : EmpowerUserDtoFromJSON(json['user']),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'validTo': !exists(json, 'validTo') ? undefined : (new Date(json['validTo'])),
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': EmpowerUserDtoToJSON(value.user),
        'token': value.token,
        'validTo': value.validTo === undefined ? undefined : (value.validTo.toISOString()),
    };
}

