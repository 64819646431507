/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicPreview,
    TopicPreviewFromJSON,
    TopicPreviewFromJSONTyped,
    TopicPreviewToJSON,
} from './TopicPreview';
import {
    TrackLeaderResponse,
    TrackLeaderResponseFromJSON,
    TrackLeaderResponseFromJSONTyped,
    TrackLeaderResponseToJSON,
} from './TrackLeaderResponse';

/**
 * 
 * @export
 * @interface Track
 */
export interface Track {
    /**
     * 
     * @type {number}
     * @memberof Track
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    resources?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    resourceDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Track
     */
    isSubscribed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    heroImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    skillLevel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Track
     */
    isNew?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Track
     */
    hidden?: boolean | null;
    /**
     * 
     * @type {Array<TrackLeaderResponse>}
     * @memberof Track
     */
    trackLeaders?: Array<TrackLeaderResponse> | null;
    /**
     * 
     * @type {Array<TopicPreview>}
     * @memberof Track
     */
    topics?: Array<TopicPreview> | null;
}

export function TrackFromJSON(json: any): Track {
    return TrackFromJSONTyped(json, false);
}

export function TrackFromJSONTyped(json: any, ignoreDiscriminator: boolean): Track {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'resourceDescription': !exists(json, 'resourceDescription') ? undefined : json['resourceDescription'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'isSubscribed': !exists(json, 'isSubscribed') ? undefined : json['isSubscribed'],
        'heroImageUrl': !exists(json, 'heroImageUrl') ? undefined : json['heroImageUrl'],
        'skillLevel': !exists(json, 'skillLevel') ? undefined : json['skillLevel'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'trackLeaders': !exists(json, 'trackLeaders') ? undefined : (json['trackLeaders'] === null ? null : (json['trackLeaders'] as Array<any>).map(TrackLeaderResponseFromJSON)),
        'topics': !exists(json, 'topics') ? undefined : (json['topics'] === null ? null : (json['topics'] as Array<any>).map(TopicPreviewFromJSON)),
    };
}

export function TrackToJSON(value?: Track | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'resources': value.resources,
        'resourceDescription': value.resourceDescription,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'isSubscribed': value.isSubscribed,
        'heroImageUrl': value.heroImageUrl,
        'skillLevel': value.skillLevel,
        'isNew': value.isNew,
        'hidden': value.hidden,
        'trackLeaders': value.trackLeaders === undefined ? undefined : (value.trackLeaders === null ? null : (value.trackLeaders as Array<any>).map(TrackLeaderResponseToJSON)),
        'topics': value.topics === undefined ? undefined : (value.topics === null ? null : (value.topics as Array<any>).map(TopicPreviewToJSON)),
    };
}

