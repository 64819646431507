/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginRequest,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
} from '../models';

export interface AuthLoginPostRequest {
    loginRequest: LoginRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async authLoginPostRaw(requestParameters: AuthLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest','Required parameter requestParameters.loginRequest was null or undefined when calling authLoginPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Auth/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async authLoginPost(requestParameters: AuthLoginPostRequest, initOverrides?: RequestInit): Promise<LoginResponse> {
        const response = await this.authLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
