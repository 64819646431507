/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDoneResponse
 */
export interface SetDoneResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SetDoneResponse
     */
    allTasksInGroupAreDone?: boolean;
}

export function SetDoneResponseFromJSON(json: any): SetDoneResponse {
    return SetDoneResponseFromJSONTyped(json, false);
}

export function SetDoneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDoneResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allTasksInGroupAreDone': !exists(json, 'allTasksInGroupAreDone') ? undefined : json['allTasksInGroupAreDone'],
    };
}

export function SetDoneResponseToJSON(value?: SetDoneResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allTasksInGroupAreDone': value.allTasksInGroupAreDone,
    };
}

