/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Topic,
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './Topic';
import {
    TrackLeaderResponse,
    TrackLeaderResponseFromJSON,
    TrackLeaderResponseFromJSONTyped,
    TrackLeaderResponseToJSON,
} from './TrackLeaderResponse';

/**
 * 
 * @export
 * @interface TopicTrack
 */
export interface TopicTrack {
    /**
     * 
     * @type {number}
     * @memberof TopicTrack
     */
    trackId?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicTrack
     */
    trackName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTrack
     */
    resources?: string | null;
    /**
     * 
     * @type {Array<TrackLeaderResponse>}
     * @memberof TopicTrack
     */
    trackLeaders?: Array<TrackLeaderResponse> | null;
    /**
     * 
     * @type {Array<Topic>}
     * @memberof TopicTrack
     */
    topics?: Array<Topic> | null;
}

export function TopicTrackFromJSON(json: any): TopicTrack {
    return TopicTrackFromJSONTyped(json, false);
}

export function TopicTrackFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicTrack {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'trackLeaders': !exists(json, 'trackLeaders') ? undefined : (json['trackLeaders'] === null ? null : (json['trackLeaders'] as Array<any>).map(TrackLeaderResponseFromJSON)),
        'topics': !exists(json, 'topics') ? undefined : (json['topics'] === null ? null : (json['topics'] as Array<any>).map(TopicFromJSON)),
    };
}

export function TopicTrackToJSON(value?: TopicTrack | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackId': value.trackId,
        'trackName': value.trackName,
        'resources': value.resources,
        'trackLeaders': value.trackLeaders === undefined ? undefined : (value.trackLeaders === null ? null : (value.trackLeaders as Array<any>).map(TrackLeaderResponseToJSON)),
        'topics': value.topics === undefined ? undefined : (value.topics === null ? null : (value.topics as Array<any>).map(TopicToJSON)),
    };
}

