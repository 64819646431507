/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Bookmark,
    BookmarkFromJSON,
    BookmarkFromJSONTyped,
    BookmarkToJSON,
} from './Bookmark';

/**
 * 
 * @export
 * @interface GetAllBookmarksResponse
 */
export interface GetAllBookmarksResponse {
    /**
     * 
     * @type {number}
     * @memberof GetAllBookmarksResponse
     */
    trackId?: number;
    /**
     * 
     * @type {Array<Bookmark>}
     * @memberof GetAllBookmarksResponse
     */
    bookmarks?: Array<Bookmark> | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllBookmarksResponse
     */
    trackTitle?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAllBookmarksResponse
     */
    trackLeaderImages?: Array<string> | null;
}

export function GetAllBookmarksResponseFromJSON(json: any): GetAllBookmarksResponse {
    return GetAllBookmarksResponseFromJSONTyped(json, false);
}

export function GetAllBookmarksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllBookmarksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'bookmarks': !exists(json, 'bookmarks') ? undefined : (json['bookmarks'] === null ? null : (json['bookmarks'] as Array<any>).map(BookmarkFromJSON)),
        'trackTitle': !exists(json, 'trackTitle') ? undefined : json['trackTitle'],
        'trackLeaderImages': !exists(json, 'trackLeaderImages') ? undefined : json['trackLeaderImages'],
    };
}

export function GetAllBookmarksResponseToJSON(value?: GetAllBookmarksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackId': value.trackId,
        'bookmarks': value.bookmarks === undefined ? undefined : (value.bookmarks === null ? null : (value.bookmarks as Array<any>).map(BookmarkToJSON)),
        'trackTitle': value.trackTitle,
        'trackLeaderImages': value.trackLeaderImages,
    };
}

