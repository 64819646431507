/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicTask,
    TopicTaskFromJSON,
    TopicTaskFromJSONTyped,
    TopicTaskToJSON,
} from './TopicTask';
import {
    WeekType,
    WeekTypeFromJSON,
    WeekTypeFromJSONTyped,
    WeekTypeToJSON,
} from './WeekType';

/**
 * 
 * @export
 * @interface TopicTaskGroup
 */
export interface TopicTaskGroup {
    /**
     * 
     * @type {Date}
     * @memberof TopicTaskGroup
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TopicTaskGroup
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTaskGroup
     */
    weeklyInfoTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicTaskGroup
     */
    weeklyInfoDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopicTaskGroup
     */
    weekNumber?: number | null;
    /**
     * 
     * @type {WeekType}
     * @memberof TopicTaskGroup
     */
    type?: WeekType;
    /**
     * 
     * @type {boolean}
     * @memberof TopicTaskGroup
     */
    isCurrentWeek?: boolean;
    /**
     * 
     * @type {Array<TopicTask>}
     * @memberof TopicTaskGroup
     */
    tasks?: Array<TopicTask> | null;
}

export function TopicTaskGroupFromJSON(json: any): TopicTaskGroup {
    return TopicTaskGroupFromJSONTyped(json, false);
}

export function TopicTaskGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicTaskGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'weeklyInfoTitle': !exists(json, 'weeklyInfoTitle') ? undefined : json['weeklyInfoTitle'],
        'weeklyInfoDescription': !exists(json, 'weeklyInfoDescription') ? undefined : json['weeklyInfoDescription'],
        'weekNumber': !exists(json, 'weekNumber') ? undefined : json['weekNumber'],
        'type': !exists(json, 'type') ? undefined : WeekTypeFromJSON(json['type']),
        'isCurrentWeek': !exists(json, 'isCurrentWeek') ? undefined : json['isCurrentWeek'],
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] === null ? null : (json['tasks'] as Array<any>).map(TopicTaskFromJSON)),
    };
}

export function TopicTaskGroupToJSON(value?: TopicTaskGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'weeklyInfoTitle': value.weeklyInfoTitle,
        'weeklyInfoDescription': value.weeklyInfoDescription,
        'weekNumber': value.weekNumber,
        'type': WeekTypeToJSON(value.type),
        'isCurrentWeek': value.isCurrentWeek,
        'tasks': value.tasks === undefined ? undefined : (value.tasks === null ? null : (value.tasks as Array<any>).map(TopicTaskToJSON)),
    };
}

