import { lazy, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Configuration, NotificationsApi } from 'api';
import { useAuth } from 'authContext';
import { mobileType } from 'utils';
import Unauthorized from 'layout/Unauthorized';
import Authorized from 'layout/Authorized';
import Wizard from 'layout/Wizard';
import Loader from 'components/Loader';
import config from 'components/Config';
import usePageTracking from 'core/usePageTracking';
import ReactGA from 'react-ga4';

const Login = lazy(() => import('routes/Login'));
const ForgotPassword = lazy(() => import('routes/ForgotPassword'));
const ResetPassword = lazy(() => import('routes/ResetPassword'));
const Invitation = lazy(() => import('routes/Invitation'));
const Bookmarks = lazy(() => import('routes/Bookmarks'));
const Account = lazy(() => import('routes/Account/Account'));
const AccountInfo = lazy(() => import('routes/Account/AccountInfo'));
const Notification = lazy(() => import('routes/Account/Notification'));
const Password = lazy(() => import('routes/Account/Password'));
const MyAmbition = lazy(() => import('routes/MyAmbition'));
const NotFound = lazy(() => import('routes/NotFound'));
const RedirectToMeeting = lazy(() => import('routes/RedirectToMeeting'));
const Track = lazy(() => import('routes/Track'));
const Tracks = lazy(() => import('routes/Tracks'));
const WizardOne = lazy(() => import('routes/Wizard/One'));
const WizardTwo = lazy(() => import('routes/Wizard/Two'));
const WizardThree = lazy(() => import('routes/Wizard/Three'));
const Comments = lazy(() => import('routes/Comments'));
const Chat = lazy(() => import('routes/Chat'));

function App() {
  const navigate = useNavigate();
  const { token, logout, user } = useAuth();

  const configuration = new Configuration({
    basePath: config.API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const notificationsApi = new NotificationsApi(configuration);

  window.didReceivePushToken = function (token, error) {
    if (token) {
      notificationsApi
        .notificationAddDeviceTokenPost({
          addDeviceTokenRequest: {
            deviceToken: token,
            email: user.email,
            mobileType: mobileType,
          },
        })
        .catch(error => {
          if (error.status === 401) {
            logout(() => navigate('/login'));
          }
        });
    }
  };

  const isProduction = config.APP_ENV === 'production';
  console.log('for GA: ', config.APP_ENV);
  if (isProduction) {
    ReactGA.initialize('G-LE1FX5HHVV');
  } else {
    ReactGA.initialize('G-Y15YEHETFD');
  }

  usePageTracking();

  return (
    <>
      <Suspense fallback={<Loader open />}>
        <Routes>
          <Route element={<Unauthorized />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:email/:token" element={<ResetPassword />} />
            <Route path="invitation/:guid" element={<Invitation />} />
            <Route path="*" element={<NotFound />} />
            <Route path="meeting" element={<RedirectToMeeting />} />
          </Route>
          <Route element={<Authorized />}>
            <Route path="/" element={<MyAmbition />} />
            <Route path="/my-ambition/:trackId" element={<MyAmbition />} />
            <Route path="bookmarks" element={<Bookmarks />} />
            <Route path="account" element={<Account />} />
            <Route path="account/info" element={<AccountInfo />} />
            <Route path="account/password" element={<Password />} />
            <Route path="account/notification" element={<Notification />} />
            <Route path="tracks" element={<Tracks />} />
            <Route path="track/:id" element={<Track />} />
            <Route path="track/:id/comments" element={<Comments />} />
            <Route path="track/:id/chat" element={<Chat />} />
          </Route>
          <Route path="wizard" to="one" element={<Wizard />}>
            <Route path="one" element={<WizardOne />} />
            <Route path="two" element={<WizardTwo />} />
            <Route path="three" element={<WizardThree />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
