import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { Configuration, MyPageApi } from 'api';
import config from 'components/Config';
import { useAuth } from 'authContext';
import { useToast } from 'toastContext';
import { parseError } from 'api/utils';

const UserInfoContext = createContext(null);

export const useUserInfo = () => useContext(UserInfoContext);

export const UserInfoProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);

  const { token, logout, isAuthenticated } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const configuration = new Configuration({
    basePath: config.API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const myPageApi = new MyPageApi(configuration);

  useEffect(() => {
    if (isAuthenticated) {
      myPageApi
        .myPageAccountInfoGet()
        .then(resp => {
          setUserInfo(resp);

          console.log(format(resp.subscriptionEndDate, 'MMMM do, yyyy'));
          const properties = {
            LAST_ACTIVE: new Date(),
          };
          window.sendinblue.identify(resp.email, properties);
        })
        .catch(async error => {
          if (error.status === 401) {
            logout(() => navigate('/login'));
          }
          const msg = await parseError(error);
          showToast(msg, 'error');
          logout(() => navigate('/login'));
        });
    }
  }, [isAuthenticated]);

  const value = {
    userInfo,
    setUserInfo,
  };

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>;
};
