/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetAllTracksResponse,
    GetAllTracksResponseFromJSON,
    GetAllTracksResponseToJSON,
    GetByIdResponse,
    GetByIdResponseFromJSON,
    GetByIdResponseToJSON,
    SubscribeRequest,
    SubscribeRequestFromJSON,
    SubscribeRequestToJSON,
    UnsubscribeRequest,
    UnsubscribeRequestFromJSON,
    UnsubscribeRequestToJSON,
} from '../models';

export interface TrackIdGetRequest {
    id: number;
}

export interface TracksSubscribePostRequest {
    subscribeRequest: SubscribeRequest;
}

export interface TracksUnsubscribePostRequest {
    unsubscribeRequest: UnsubscribeRequest;
}

/**
 * 
 */
export class TracksApi extends runtime.BaseAPI {

    /**
     */
    async trackIdGetRaw(requestParameters: TrackIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetByIdResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trackIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Track/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetByIdResponseFromJSON(jsonValue));
    }

    /**
     */
    async trackIdGet(requestParameters: TrackIdGetRequest, initOverrides?: RequestInit): Promise<GetByIdResponse> {
        const response = await this.trackIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tracksGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllTracksResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Tracks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllTracksResponseFromJSON(jsonValue));
    }

    /**
     */
    async tracksGet(initOverrides?: RequestInit): Promise<GetAllTracksResponse> {
        const response = await this.tracksGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async tracksSubscribePostRaw(requestParameters: TracksSubscribePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subscribeRequest === null || requestParameters.subscribeRequest === undefined) {
            throw new runtime.RequiredError('subscribeRequest','Required parameter requestParameters.subscribeRequest was null or undefined when calling tracksSubscribePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Tracks/Subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeRequestToJSON(requestParameters.subscribeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tracksSubscribePost(requestParameters: TracksSubscribePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.tracksSubscribePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tracksUnsubscribePostRaw(requestParameters: TracksUnsubscribePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.unsubscribeRequest === null || requestParameters.unsubscribeRequest === undefined) {
            throw new runtime.RequiredError('unsubscribeRequest','Required parameter requestParameters.unsubscribeRequest was null or undefined when calling tracksUnsubscribePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Tracks/Unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsubscribeRequestToJSON(requestParameters.unsubscribeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tracksUnsubscribePost(requestParameters: TracksUnsubscribePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.tracksUnsubscribePostRaw(requestParameters, initOverrides);
    }

}
