/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrackLeaderResponse
 */
export interface TrackLeaderResponse {
    /**
     * 
     * @type {number}
     * @memberof TrackLeaderResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrackLeaderResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackLeaderResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackLeaderResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackLeaderResponse
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackLeaderResponse
     */
    signature?: string | null;
}

export function TrackLeaderResponseFromJSON(json: any): TrackLeaderResponse {
    return TrackLeaderResponseFromJSONTyped(json, false);
}

export function TrackLeaderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackLeaderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
    };
}

export function TrackLeaderResponseToJSON(value?: TrackLeaderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'title': value.title,
        'image': value.image,
        'signature': value.signature,
    };
}

