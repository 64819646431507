/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskResponse
 */
export interface TaskResponse {
    /**
     * 
     * @type {number}
     * @memberof TaskResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TaskResponse
     */
    duration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TaskResponse
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    taskType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    link?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaskResponse
     */
    optional?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof TaskResponse
     */
    liveTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaskResponse
     */
    summaryAIGenerated?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    summary?: string | null;
}

export function TaskResponseFromJSON(json: any): TaskResponse {
    return TaskResponseFromJSONTyped(json, false);
}

export function TaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'taskType': !exists(json, 'taskType') ? undefined : json['taskType'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
        'liveTime': !exists(json, 'liveTime') ? undefined : (json['liveTime'] === null ? null : new Date(json['liveTime'])),
        'summaryAIGenerated': !exists(json, 'summaryAIGenerated') ? undefined : json['summaryAIGenerated'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function TaskResponseToJSON(value?: TaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'duration': value.duration,
        'sortOrder': value.sortOrder,
        'taskType': value.taskType,
        'link': value.link,
        'optional': value.optional,
        'liveTime': value.liveTime === undefined ? undefined : (value.liveTime === null ? null : value.liveTime.toISOString()),
        'summaryAIGenerated': value.summaryAIGenerated,
        'summary': value.summary,
    };
}

