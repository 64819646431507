/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityType {
    TaskUnchecked = 'TaskUnchecked',
    TaskChecked = 'TaskChecked',
    TaskRead = 'TaskRead',
    MeetingAttended = 'MeetingAttended',
    TopicCompleted = 'TopicCompleted',
    DiplomaDownloaded = 'DiplomaDownloaded',
    WhiteboardRead = 'WhiteboardRead'
}

export function ActivityTypeFromJSON(json: any): ActivityType {
    return ActivityTypeFromJSONTyped(json, false);
}

export function ActivityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityType {
    return json as ActivityType;
}

export function ActivityTypeToJSON(value?: ActivityType | null): any {
    return value as any;
}

